export enum guestActionTypes {
  GET_GUEST_BY_ID = "GET_GUEST_BY_ID",
  GET_GUEST_BY_ID_SUCCESS = "GET_GUEST_BY_ID_SUCCESS",

  GET_GUESTS_LIST_LOADING = "GET_GUESTS_LIST_LOADING",

  GET_ALL_GUESTS = "GET_ALL_GUESTS",
  GET_ALL_GUESTS_SUCCESS = "GET_ALL_GUESTS_SUCCESS",

  UPDATE_GUEST_PROFILE = "UPDATE_GUEST_PROFILE",
  UPDATE_GUEST_PROFILE_SUCCESS = "UPDATE_GUEST_PROFILE_SUCCESS",

  GET_GUEST_SALON = "GET_GUEST_SALON",
  GET_GUEST_SALON_SUCCESS = "GET_GUEST_SALON_SUCCESS",

  DELETE_GUEST_SALON = "DELETE_GUEST_SALON",

  UPDATE_GUEST_SALON = "UPDATE_GUEST_SALON",
  UPDATE_GUEST_SALON_SUCCESS = "UPDATE_GUEST_SALON_SUCCESS",

  CLEAR_GUEST_PROFILE = "CLEAR_GUEST_PROFILE",
  CLEAR_GUEST_ID = "CLEAR_GUEST_ID",

  CREATE_GUEST_SALON = "CREATE_GUEST_SALON",
  CREATE_GUEST_SALON_SUCCESS = "CREATE_GUEST_SALON_SUCCESS",
  CREATE_GUEST_SALON_FAILURE = "CREATE_GUEST_SALON_FAILURE",
  GET_ALL_GUESTS_LIST = "GET_ALL_GUESTS_LIST",
  GET_ALL_GUESTS_LIST_SUCCSSES = "GET_ALL_GUESTS_LIST_SUCCSSES",

  SAVE_GUEST_PERSONAL_DETAILS_EDIT = "SAVE_GUEST_PERSONAL_DETAILS_EDIT",
  CLEAR_GUEST_PERSONAL_DETAILS_EDIT = "CLEAR_GUEST_PERSONAL_DETAILS_EDIT",

  SAVE_UNASSIGNED_GUEST_SELECTED_SALON = "SAVE_UNASSIGNED_GUEST_SELECTED_SALON",
  CLEAR_UNASSIGNED_GUEST_SELECTED_SALON = "CLEAR_UNASSIGNED_GUEST_SELECTED_SALON"
}
